var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _vm._m(0),
    _c(
      "section",
      { staticClass: "section" },
      [
        _vm.isLoading
          ? _c(
              "div",
              { staticClass: "container container--fullWidth" },
              [_c("VLoadSpinner")],
              1
            )
          : [
              _vm._m(1),
              _c(
                "div",
                {
                  staticClass:
                    "container container--fullWidth grid grid__col-small-big",
                },
                [
                  _vm._m(2),
                  _c(
                    "div",
                    { staticClass: "wrapper--filled wrapper--paddingLg" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.closeMenuDropdown,
                              expression: "closeMenuDropdown",
                            },
                          ],
                          staticClass: "formGroup dropdown",
                        },
                        [
                          _c("label", { staticClass: "formLabel" }, [
                            _vm._v("Country"),
                          ]),
                          _c(
                            "a",
                            {
                              staticClass:
                                "formInput formInput--fullWidth formInput--selectCustom",
                              on: { click: _vm.toggleMenuDropdown },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.authenticatedUser.country
                                    ? _vm.authenticatedUser.country
                                    : "Select country"
                                )
                              ),
                              _c("icon", { attrs: { name: "arrow-down" } }),
                            ],
                            1
                          ),
                          _vm.menuDropDown
                            ? _c(
                                "ul",
                                {
                                  staticClass:
                                    "dropdown__menu dropdown__menu--withInput",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.searchInput,
                                        expression: "searchInput",
                                      },
                                      {
                                        name: "focus",
                                        rawName: "v-focus",
                                        value: true,
                                        expression: "true",
                                      },
                                    ],
                                    staticClass:
                                      "formInput formInput--fullWidth",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Filter Countries",
                                    },
                                    domProps: { value: _vm.searchInput },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.searchInput = $event.target.value
                                      },
                                    },
                                  }),
                                  _vm._l(_vm.filteredCountry, function (c) {
                                    return _c(
                                      "li",
                                      {
                                        key: c,
                                        staticClass:
                                          "dropdown__item dropdown__item--sm",
                                        class: {
                                          isActive:
                                            c === _vm.authenticatedUser.country,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateCountry(c)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(c))]
                                    )
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "container container--fullWidth grid grid__col-small-big",
                },
                [
                  _vm._m(3),
                  _c(
                    "div",
                    { staticClass: "wrapper--filled wrapper--paddingLg" },
                    [
                      _c("div", { staticClass: "formGroup" }, [
                        _c("label", { staticClass: "formLabel" }, [
                          _vm._v("PayPal Email Address"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.authenticatedUser.paypal_email,
                              expression: "authenticatedUser.paypal_email",
                            },
                          ],
                          staticClass: "formInput formInput--fullWidth",
                          attrs: {
                            name: "paypal email",
                            type: "text",
                            placeholder: "Email for payments",
                          },
                          domProps: {
                            value: _vm.authenticatedUser.paypal_email,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.authenticatedUser,
                                "paypal_email",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
      ],
      2
    ),
    _c("div", { staticClass: "fabWrapper fabWrapper--right" }, [
      _c(
        "a",
        {
          staticClass: "btn btn--primary",
          on: { click: _vm.updateUserFields },
        },
        [_vm._v("SAVE CHANGES")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "header header--borderBottom" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h1", [_vm._v("Selling Preferences")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "container container--fullWidth grid grid__col-small-big",
      },
      [
        _c("div", { staticClass: "h-margin-30" }, [
          _c("h2", [_vm._v("TheCharts Payouts")]),
          _c("p", [_vm._v("Informations")]),
        ]),
        _c("div", { staticClass: "wrapper--filled wrapper--paddingLg" }, [
          _c("h2", [
            _vm._v("TheCharts commission breakdown per Membership Tier: "),
          ]),
          _c("br"),
          _c("p", [
            _c("strong", [_vm._v("Elite Accounts")]),
            _vm._v(": TheCharts commissions are 0% on every sale."),
          ]),
          _c("br"),
          _c("p", [
            _c("strong", [_vm._v("Standard Accounts")]),
            _vm._v(": TheCharts commissions are 30% on every sale."),
          ]),
          _c("br"),
          _c("p", [
            _vm._v(
              "Elite Accounts will receive instant payouts from all sales received via their profile store and embedded Amplify Player. Payouts from all sales received via TheCharts Marketplace will be issued the following business day via PayPal."
            ),
          ]),
          _c("br"),
          _c("p", [
            _vm._v(
              "Due to limited access to PayPal features we cannot split marketplace payments as they come through on the platform, so we are forced to collect them on our account and we then make payouts for all sales every morning to your PayPal account."
            ),
          ]),
          _c("br"),
          _c("p", [
            _vm._v(
              "For this same reason, every dispute will affect our account, and in case of lost disputes the money will be deducted from your next payout. You will also be notified of the case and evidence will be provided if necessary."
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h-margin-30" }, [
      _c("h2", [_vm._v("Billing Information")]),
      _c("p", [
        _vm._v(
          "Country of residence/registration, needed for tax legislations."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "h-margin-30" }, [
      _c("h2", [_vm._v("PayPal")]),
      _c("p", [_vm._v("Set the email used for direct payments from PayPal")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }